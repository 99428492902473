<template>
    <AppLayout>
        <RequireLogin>
            <Snackbar :attr="snackbar" />
            <v-snackbar v-model="snackbarAccountNameUpdated" :timeout="2000" top color="blue" class="mt-0 pt-0">
                <span>Account name updated</span>
                <v-btn text class="blue white--text" @click="snackbarAccountNameUpdated = false"><font-awesome-icon icon="check" fixed-width/></v-btn>
            </v-snackbar>
            <v-snackbar v-model="snackbarAccountNameUpdateFailed" :timeout="2000" top color="red" class="mt-0 pt-0">
                <span>Failed to update account name</span>
                <v-btn text class="red white--text" @click="snackbarAccountNameUpdateFailed = false"><font-awesome-icon icon="check" fixed-width/></v-btn>
            </v-snackbar>
            <v-snackbar v-model="snackbarAccountDeleted" :timeout="2000" top color="blue" class="mt-0 pt-0">
                <span>Account deleted</span>
                <v-btn text class="blue white--text" @click="snackbarAccountDeleted = false"><font-awesome-icon icon="check" fixed-width/></v-btn>
            </v-snackbar>
            <v-snackbar v-model="snackbarAccountDeleteFailed" :timeout="2000" top color="red" class="mt-0 pt-0">
                <span>Failed to delete account</span>
                <v-btn text class="red white--text" @click="snackbarAccountDeleteFailed = false"><font-awesome-icon icon="check" fixed-width/></v-btn>
            </v-snackbar>
             <v-snackbar v-model="snackbarGravatarUpdated" :timeout="2000" top color="blue" class="mt-0 pt-0">
                <span>Gravatar setting updated</span>
                <v-btn text class="blue white--text" @click="snackbarGravatarUpdated = false"><font-awesome-icon icon="check" fixed-width/></v-btn>
            </v-snackbar>
             <v-snackbar v-model="snackbarGravatarUpdateFailed" :timeout="2000" top color="red" class="mt-0 pt-0">
                <span>Failed updating Gravatar setting</span>
                <v-btn text class="red white--text" @click="snackbarGravatarUpdateFailed = false"><font-awesome-icon icon="check" fixed-width/></v-btn>
            </v-snackbar>
            <v-snackbar v-model="snackbarAvatarUpdated" :timeout="2000" top color="blue" class="mt-0 pt-0">
                <span>Avatar updated</span>
                <v-btn text class="blue white--text" @click="snackbarAvatarUpdated = false"><font-awesome-icon icon="check" fixed-width/></v-btn>
            </v-snackbar>
             <v-snackbar v-model="snackbarAvatarUpdateFailed" :timeout="2000" top color="red" class="mt-0 pt-0">
                <span>Failed updating avatar</span>
                <v-btn text class="red white--text" @click="snackbarAvatarUpdateFailed = false"><font-awesome-icon icon="check" fixed-width/></v-btn>
            </v-snackbar>
            <Tutorial next="/preferences">
                <p class="text-subtitle-1 font-weight-light text-center">The <b>account settings</b> is where you may change things such as account name and automatic lockout time. You can return to this page at any time by selecting the <b>Account</b> tab in the toolbar.</p>
            </Tutorial>
            <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">Account settings</h1>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <p class="text-overline mb-0">Account type</p>
                    <p>
                        <span>{{ currentAccount.type }}</span>
                    </p>
                    <p class="text-overline mb-0">Account name</p>
                    <p class="mb-0 pb-0">
                        <span>{{ displayName }}</span>
                        <v-btn class="blue--text no-print" @click="dialogEditDisplayName = true" icon>
                            <font-awesome-icon :icon="['fas', 'pencil-alt']" fixed-width size="1x"/>
                        </v-btn>
                    </p>
                    <v-dialog v-model="dialogEditDisplayName" max-width="600">
                        <v-card elevation="4" class="pa-5">
                            <v-card-text class="text-h6 pa-0 pb-3">Edit account name</v-card-text>
                            <v-row justify="center" class="pt-3">
                                <v-col cols="12">
                                    <div class="font-weight-light text-body-1">
                                        <p>We will use this name to reference your account on our website and when we communicate by email, telephone, or letters.</p>
                                        <template v-if="currentAccount">
                                            <p v-show="currentAccount.type === ACCOUNT_TYPE_FREE">
                                                We recommend using your name here.
                                            </p>
                                            <p v-show="currentAccount.type === ACCOUNT_TYPE_PERSONAL">
                                                It is also the name that will appear on the invoice. We recommend using your name here.
                                            </p>
                                            <p v-show="currentAccount.type === ACCOUNT_TYPE_ENTERPRISE">
                                                It is also the legal name of the organization
                                                that will appear on the invoice.
                                            </p>
                                        </template>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-form v-model="editAccountNameForm" ref="editAccountNameFormRef" @submit="validateEditAccountName" onSubmit="return false;" @keyup.enter.native="validateEditAccountName">
                                <v-text-field
                                    v-model=name
                                    label="Account name"
                                    :rules="nameRules"
                                    outlined
                                ></v-text-field>
                                <v-card-actions>
                                    <v-row justify="center">
                                    </v-row>
                                </v-card-actions>
                            </v-form>
                            <v-row justify="center">
                                <v-btn elevation="4" class="blue white--text" @click="editAccountName" :disabled="!isNameEditEnabled">
                                    Save
                                </v-btn>
                                <v-btn text class="grey--text" @click="dialogEditDisplayName = false">Cancel</v-btn>
                            </v-row>
                        </v-card>
                    </v-dialog>
                </v-col>
            </v-row>

            <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <p class="text-overline mb-0">Account image</p>
                    <p>
                        <Avatar :attr="currentAccount" :size="36"/>
                        <v-btn class="blue--text no-print" @click="dialogEditAccountImage = true" icon>
                            <font-awesome-icon :icon="['fas', 'pencil-alt']" fixed-width size="1x"/>
                        </v-btn>
                    </p>
                    <!-- </v-hover> -->
                    <v-dialog v-model="dialogEditAccountImage" max-width="600">
                        <v-card elevation="4" class="pa-5">
                        <v-card-text class="text-h6 pa-0 pb-3">Edit account image</v-card-text>
                            <template v-if="currentAccount.type === ACCOUNT_TYPE_FREE || currentAccount.type === ACCOUNT_TYPE_PERSONAL">
                                <v-row justify="start" class="px-5">
                                    <v-switch
                                        v-model="isGravatarEnabled"
                                        color="blue"
                                        hide-details
                                    >
                                    <template #label>
                                        <span style="margin-left: 4px">Use Gravatar</span>
                                    </template>
                                    </v-switch>
                                </v-row>
                                <v-row justify="start" class="px-5">
                                    <v-input>
                                        <template #label>
                                        <span style="margin-left: 52px; font-size: 0.8em;"><TextLink href="https://gravatar.com" target="_blank">What is Gravatar?</TextLink></span>
                                        </template>
                                    </v-input>
                                </v-row>
                            </template>
                            <template v-if="currentAccount.type === ACCOUNT_TYPE_PERSONAL || currentAccount.type === ACCOUNT_TYPE_ENTERPRISE">
                                <v-row justify="start" class="px-5 pt-3">
                                        <span :style="{ opacity: currentAccount.isGravatarEnabled ? 0.5 : 1 }" v-if="currentAccount.isGravatarEnabled">
                                            <Avatar :attr="currentAccountImageWithGravatarDisabled" :size="36"/>
                                        </span>
                                        <span v-if="!currentAccount.isGravatarEnabled">
                                            <Avatar :attr="currentAccountImageWithGravatarDisabled" :size="36"/>
                                        </span>
                                        <v-btn class="blue--text" @click="dialogUploadAccountImage = true" text :disabled="currentAccount.isGravatarEnabled" icon>
                                            <!-- Upload -->
                                            <font-awesome-icon :icon="['fas', 'cloud-upload-alt']" fixed-width size="1x"/>
                                        </v-btn>
                                        <v-btn class="red--text" @click="dialogDeleteAccountImage = true" text :disabled="currentAccount.isGravatarEnabled" icon v-if="currentAccount.icon">
                                            <!-- Delete -->
                                            <font-awesome-icon :icon="['fas', 'trash']" fixed-width size="1x"/>
                                        </v-btn>
                                </v-row>
                            </template>
                            <v-row justify="center" class="pb-2">
                                <v-btn text class="black--text" @click="dialogEditAccountImage = false">Close</v-btn>
                            </v-row>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogUploadAccountImage" max-width="600">
                        <v-card elevation="4" class="pa-5">
                            <v-card-text class="text-h6 pa-0 pb-3">Upload account image</v-card-text>
                            <v-row justify="center" class="px-5 pt-3">
                                <v-file-input
                                    v-model="editableAccountImage"
                                    show-size
                                    accept="image/png"
                                    :rules="avatarRules"
                                    label="Choose account image (.png)"
                                    outlined>
                                </v-file-input>
                            </v-row>
                            <v-row justify="center" class="pb-2">
                                <v-btn elevation="4" class="blue white--text" @click="editAccountImage" :disabled="!isAvatarComplete">
                                    Save
                                </v-btn>
                                <v-btn text class="grey--text" @click="dialogUploadAccountImage = false">Cancel</v-btn>
                            </v-row>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogDeleteAccountImage" max-width="600">
                        <v-card elevation="4" class="pa-5">
                            <v-card-text class="text-h6 pa-0 pb-3">Delete account image</v-card-text>
                            <v-row justify="center" class="px-5 pt-3 my-5">
                                <Avatar :attr="currentAccountImageWithGravatarDisabled" :size="36"/>
                            </v-row>
                            <v-row justify="center" class="pb-2">
                                <v-btn elevation="4" class="red white--text" @click="deleteAccountImage" :disabled="!isAvatarComplete">
                                    Delete
                                </v-btn>
                                <v-btn text class="grey--text" @click="dialogDeleteAccountImage = false">Cancel</v-btn>
                            </v-row>
                        </v-card>
                    </v-dialog>
                </v-col>
            </v-row>

            <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <p class="text-overline mb-0">Other</p>
                    <p>
                        <router-link :to="{ path: '/account/delete', query: { acct: this.$route.query.acct } }">How to delete this account</router-link>
                    </p>
                </v-col>
            </v-row>
        </RequireLogin>
    </AppLayout>
</template>

<style lang="css" scoped>
/* p.pointer {
     cursor: pointer;
}
span.pointer {
     cursor: pointer;
} */
@media print {
  /* add margin around the main content to separate it from the page header and footer */
  @page {
    margin-top: 2cm;
    margin-bottom: 2cm;
  }
  /* removes padding corresponding to navbar and footer when printing document, adds one line of padding to top of document to separate page title from page content */
  .print {
    padding: 0 !important;
  }
  /* hides elements that should not appear in print */
  .no-print {
      display: none !important;
  }

}
</style>

<script>
import { mapState, mapGetters } from 'vuex';
import AppLayout from '@/components/AppLayout.vue';
import RequireLogin from '@/components/RequireLogin.vue';
import Tutorial from '@/components/Tutorial.vue';
import Snackbar from '@/components/Snackbar.vue';
import Avatar from '@/components/Avatar.vue';
import TextLink from '@/components/TextLink.vue';
import { isValidName, compact } from '@/sdk/input';
import { ACCOUNT_TYPE_ENTERPRISE, ACCOUNT_TYPE_PERSONAL, ACCOUNT_TYPE_FREE } from '@/constants';

export default {
    components: {
        AppLayout,
        RequireLogin,
        Tutorial,
        Snackbar,
        Avatar,
        TextLink,
    },
    data() {
        return {
            snackbar: {},
            snackbarAccountNameUpdated: false,
            snackbarAccountNameUpdateFailed: false,
            snackbarAccountDeleted: false,
            snackbarAccountDeleteFailed: false,
            snackbarGravatarUpdated: false,
            snackbarGravatarUpdateFailed: false,
            snackbarAvatarUpdated: false,
            snackbarAvatarUpdateFailed: false,
            dialogDeleteAccount: false,
            editAccountNameForm: false,
            displayName: null,
            dialogEditDisplayName: false,
            dialogUploadAccountImage: false,
            dialogEditAccountImage: false,
            dialogDeleteAccountImage: false,
            /*
            NOTE: due to a bug in vuetify, the editableAccountImage variable MUST be initialized
            to an empty array to avoid this TypeError: cannot read property 'length' of undefined.
            However, even though it starts as an empty array, when the user selects a file in the
            single-file input field, vuetify changes the variable's value to a File object
            (not in an array).
            */
            editableAccountImage: [],
            nameRules: [
                (v) => isValidName(v) || 'Enter the name that should appear on the invoice',
                (v) => !v || v === this.currentAccount.name || !this.compactAccountNames.includes(compact(v)) || 'You already have an account with a similar name',
            ],
            avatarRules: [
                (v) => (v && v.size < 400 * 1024) || 'File size should be less than 400 KB!',
            ],
        };
    },

    /*
    props: ['isAuthenticated', 'isReady', 'isGravatarEnabled'],
*/
    computed: {
        ...mapState({
            isReady: (state) => state.isReady,
            user: (state) => state.user,
            accountMap: (state) => state.accountMap,
            currentAccountId: (state) => state.accountId,
        }),
        ...mapGetters({
            currentAccount: 'account', // currently selected account object
            combinedAccountList: 'combinedAccountList',
        }),
        compactAccountNames() {
            return this.combinedAccountList.map((item) => compact(item.name));
        },
        currentAccountImageWithGravatarDisabled() {
            return { icon: this.currentAccount.icon, isGravatarEnabled: false };
        },
        // https://vuex.vuejs.org/guide/forms.html
        isGravatarEnabled: {
            get() {
                if (this.currentAccount) {
                    return this.currentAccount.isGravatarEnabled;
                }

                return false;
            },
            async set(value) {
                const result = await this.$store.dispatch('editAccount', { id: this.currentAccount.id, isGravatarEnabled: value });
                if (result) {
                    this.snackbarGravatarUpdated = true;
                } else {
                    this.snackbarGravatarUpdateFailed = true;
                }
            },
        },
        name: {
            get() {
                if (this.currentAccount) {
                    return this.currentAccount.name;
                }

                return '';
            },
            set(value) {
                this.editableName = value;
            },
        },
        isNameEditEnabled() {
            return this.currentAccount
            && this.currentAccount.permit
            && this.currentAccount.permit.includes('edit')
            && this.editableName
            && this.editableName !== this.currentAccount.name;
        },
        isAvatarComplete() {
            return this.editableAccountImage;
        },
        ACCOUNT_TYPE_ENTERPRISE() {
            return ACCOUNT_TYPE_ENTERPRISE;
        },
        ACCOUNT_TYPE_PERSONAL() {
            return ACCOUNT_TYPE_PERSONAL;
        },
        ACCOUNT_TYPE_FREE() {
            return ACCOUNT_TYPE_FREE;
        },
    },

    watch: {
        // isGravatarEnabled(val, oldval) {
        //     console.log('isGravatarEnabled: %o (was: %o)', val, oldval);
        //     this.isGravatarEnabled = val;
        // },
        // Close when clicking outside of dialog
        isReady(value, oldValue) {
            console.log('account.vue: isReady changed from %o to %o', oldValue, value);
            // only call init again if ready changed from false to true after mounted()
            if (value && !oldValue) {
                this.init();
            }
        },
        dialogDeleteAccount(val) {
            if (!val) {
                this.closeDialogDeleteAccount();
            }
        },
    },

    created() {
        console.log('created'); // : isAuthenticated: %o', this.isAuthenticated);
        if (this.$route.query.acct) {
            const queryAccountId = this.$route.query.acct;
            if (queryAccountId !== this.currentAccountId) {
                console.log(`account.vue: setting current account to ${queryAccountId}`);
                this.$store.commit('setAccountId', queryAccountId);
            }
        }
    },

    mounted() {
        console.log('mounted');
        if (this.isReady) {
            this.init();
        }
    },

    methods: {
        async init() {
            this.displayName = this.currentAccount.name;
        },
        validateEditAccountName() {
            if (this.$refs.editAccountNameFormRef.validate()) {
                this.editAccountName();
            }
        },
        async editAccountName() {
            if (this.editableName) {
                const result = await this.$store.dispatch('editAccount', { id: this.currentAccount.id, name: this.editableName });
                if (result) {
                    this.snackbarAccountNameUpdated = true;
                } else {
                    this.snackbarAccountNameUpdateFailed = true;
                }
                this.displayName = this.editableName;
                this.dialogEditDisplayName = false;
            }
        },
        editGravatarEnabled() {
            console.log('editGravatarEnabled');
            this.isGravatarEnabled = !this.isGravatarEnabled;
        },
        async editAccountImage() {
            /*
            See the note about editableAccountImage in the 'data' section; because we are
            using a single-file input, the value of editableAccountImage is set to a single
            File object by the time this method is called.
            */
            // console.log('editableAccountImage: %o', this.editableAccountImage); // {name: "icon.png", lastModified: 1545595273000, lastModifiedDate: a Date object, size: 8887, ... }
            if (this.editableAccountImage.size > 0) {
                const result = await this.$store.dispatch('editAccountImage', this.editableAccountImage);
                if (result) {
                    this.snackbarAvatarUpdated = true;
                } else {
                    this.snackbarAvatarUpdateFailed = true;
                }
                this.dialogUploadAccountImage = false;
            }
        },
        async deleteAccountImage() {
            const result = await this.$store.dispatch('deleteAccountImage');
            if (result) {
                this.snackbarAvatarUpdated = true;
            } else {
                this.snackbarAvatarUpdateFailed = true;
            }
            this.dialogDeleteAccountImage = false;
        },
    },
};
</script>
